import { message } from "antd";
const useApi = () => {
    const post = async (path, body) => {
        return await fetch(`https://api.solmarketmaker.com${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(response => response.json())

    }
    return { post }
}

const useNotification = () => {

    const success = (content) => {
        message.success(content);
    };

    const error = (content) => {
        message.error(content);
    };

    const warning = (content) => {
        message.warning(content);
    };

    return {
        success,
        error,
        warning
    }
};
export {
    useApi,
    useNotification
};