import { NavLink } from 'react-router-dom';
import { Row, Col } from "antd";

const HeaderCompontent = () => {
    return (
        <Row>
            <Col span={10} offset={7}>
                <Row justify="space-around" className="header">
                    <NavLink to="/ray">Raydium MM</NavLink>
                    <NavLink to="/pumpfun">Pumpfun MM</NavLink>
                    <NavLink to="/bulk-sender">Bulk Sender</NavLink>
                    <NavLink to="/bundle-pumpfun">Bundle Pumpfun</NavLink>
                </Row>
            </Col>
        </Row>
    );
};

export default HeaderCompontent;