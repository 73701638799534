import { useState, useEffect } from 'react';
import { Row, Col, Button, Input, InputNumber, Checkbox, Slider, Tooltip, Tag, Space } from 'antd';
import { MinusCircleOutlined, CloseCircleOutlined, EyeOutlined, EyeInvisibleOutlined, SaveOutlined, ImportOutlined, LoadingOutlined, CheckCircleOutlined, LineChartOutlined, QuestionCircleOutlined, RedoOutlined } from '@ant-design/icons';
import ReactCodeMirror from '@uiw/react-codemirror';
import { useApi, useNotification } from '../hooks';

const PumpfunComponent = ({ onNewLog }) => {
  const { post } = useApi();
  const { success, error, warning } = useNotification();
  const formatter = (value) => `${value}%`;
  const [privateKey, setPrivateKey] = useState([
  ]);
  const [tokenAddress, setTokenAddress] = useState("");
  const [slippage, setSlippage] = useState(10);
  const [tip, setTip] = useState(0.005);
  const [amount, setAmount] = useState(0);
  const [percent, setPercent] = useState(100);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [isBuyLoading, setIsBuyLoading] = useState(false);
  const [isSellLoading, setIsSellLoading] = useState(false);
  const [isEyesOpen, setIsEyesOpen] = useState(true);
  const [selectedAll, setSelectedAll] = useState(false);
  const [data, setData] = useState({
    "data": [],
    "sum": {
      "solBalance": 0,
      "worth": 0
    }
  });
  useEffect(() => {
    //get private key from local storage
    const privateKey = localStorage.getItem('privateKey');
    if (privateKey) {
      setPrivateKey(privateKey.split('\n'));
    }
  }, [])

  useEffect(() => {
    getTokenInfo();
    getBalance();
  }, [tokenAddress])

  useEffect(() => {
    setData({
      ...data,
      data: data.data.map(item => {
        return {
          ...item,
          isSelected: selectedAll
        }
      })
    })
  }, [selectedAll])

  async function onImportClicked() {
    setIsImportLoading(true);
    try {
      await getBalance();
    } catch (error) {

    }
    finally {
      setIsImportLoading(false);
    }
  };

  async function getTokenInfo() {
    var response = await post('/getTokenInfo',
      {
        mint: tokenAddress
      }
    )
    if (response.mint) {
      setTokenAddress(response.mint);
      onNewLog([{
        time: new Date().toLocaleString(),
        type: 0,
        value: `Token detected: ${response.mint} name: ${response.name} symbol: ${response.symbol}`,
        status: 1
      }]);
    }
  }

  async function onSave() {
    if (privateKey.length === 0) return;
    //save to local storage
    localStorage.setItem('privateKey', privateKey.join('\n'));
    success('Private keys saved successfully!');
  }

  async function onRefresh() {
    setIsRefreshLoading(true);
    setData({
      "data": [],
      "sum": {
        "solBalance": 0,
        "worth": 0
      }
    });
    try {
      await getBalance();
    } catch (error) {

    }
    finally {
      setIsRefreshLoading(false);
    }
  }

  function onSelectedAllChecked(e) {
    setSelectedAll(!selectedAll);
  }

  function onPrivateKeysChange(e) {
    setPrivateKey(e.split('\n'));
  };

  function shortenAddress(address) {
    return address.slice(0, 5) + '...' + address.slice(-5);
  }

  function onItemChecked(item) {
    setData({
      ...data,
      data: data.data.map(dataItem => {
        if (dataItem.publicKey === item.publicKey) {
          return {
            ...dataItem,
            isSelected: !dataItem.isSelected
          }
        }
        return dataItem;
      })
    });
    // setSelectedAll(false);
  }

  async function onBuyClick() {
    setIsBuyLoading(true);
    //fetch all selected items
    const privateKeys = data.data.filter(item => item.isSelected).map(item => item.privateKey);
    if (privateKeys.length === 0) {
      warning('Please select at least one wallet to buy');
      setIsBuyLoading(false);
      return;
    }
    onNewLog([{
      time: new Date().toLocaleString(),
      value: `Buying ${amount} SOL of token ${tokenAddress} of ${privateKeys.length} wallets. Please wait...`,
      status: 2
    }])
    const response = await post('/buyPump', {
      mint: tokenAddress,
      privateKeys,
      amount,
      tip,
      slippage
    });
    onNewLog(response.map(item => {
      return {
        time: new Date().toLocaleString(),
        value: 'TxHash: ' + item.signature,
        status: item.confirmed ? 1 : 0
      }
    }))
    // console.log(response);
    //add response to first element of log
    // setLog([response, ...log]);
    // await getBalance();
    setIsBuyLoading(false);
  }

  async function onSellClick() {
    setIsSellLoading(true);
    //fetch all selected items
    const privateKeys = data.data.filter(item => item.isSelected).map(item => item.privateKey);
    if (privateKeys.length === 0) {
      warning('Please select at least one wallet to sell');
      setIsSellLoading(false);
      return;
    }
    onNewLog([{
      time: new Date().toLocaleString(),
      value: `Selling ${percent}% of token ${tokenAddress} of ${privateKeys.length} wallets. Please wait...`,
      status: 2
    }])
    const response = await post('/sellPump', {
      mint: tokenAddress,
      privateKeys,
      percent,
      tip,
      slippage,
    });

    onNewLog(response.map(item => {
      return {
        time: new Date().toLocaleString(),
        value: 'TxHash: ' + item.signature,
        status: item.confirmed ? 1 : 0
      }
    }))
    // await getBalance();
    setIsSellLoading(false);
  }

  async function getBalance() {
    const response = await post('/fetchBalanceByMint',
      {
        mint: tokenAddress,
        privateKeys: privateKey.slice(0, 20),
      }
    );
    setData(response);
    setSelectedAll(false);
  }

  return (
    <div className='mt-2'>
      <Row>
        <Col span={14}>
          <Row>
            <Col><b>Private keys <span className="text-danger">(maximum 20 wallets)</span></b></Col>
            <Col span={24}>
              <div className='bg-grey'>
                <div className={!isEyesOpen ? 'blur' : ''} style={{ textAlign: 'left', overflow: 'auto' }}>
                  <ReactCodeMirror
                    minHeight='300px'
                    maxHeight='300px'
                    onChange={onPrivateKeysChange}
                    value={privateKey.join('\n')}
                  />
                </div>
                {isEyesOpen ? <EyeOutlined className='eyes' style={{ fontSize: '28px', color: "#4096ff" }} onClick={() => { setIsEyesOpen(false) }} /> : <EyeInvisibleOutlined style={{ fontSize: '28px' }} className='eyes' onClick={() => { setIsEyesOpen(true) }} />}
              </div>
            </Col>
          </Row>
          <Row justify="center" className='mt-3'>
            <Button type="primary" icon={<SaveOutlined />} onClick={onSave}>
              Save List
            </Button>
            <Button style={{ marginLeft: "1rem" }} onClick={onImportClicked} disabled={isImportLoading} type="primary" icon={isImportLoading ? <LoadingOutlined /> : <ImportOutlined />}>
              Import
            </Button>

          </Row>
          {/* <Button className='mt-2' variant="primary" onClick={onImportClicked}>
            {isImportLoading ?
              <div class="spinner-border" role="status">
              </div> : "Import"}
            </Button> */}
        </Col>
        <Col span={9} offset={1}>
          <Row justify="center">
            <Col>
              <h3>Configuration</h3>
            </Col>
          </Row>
          <Row justify="start" className='mt-2'>
            <Col span={24}>
              <b>Token Mint</b>
            </Col>
            <Col span={24} className='mt-1'>
              <Space.Compact
                style={{
                  width: '100%',
                }}
              >
                <Input value={tokenAddress} placeholder='Please input token address' onChange={(e) => setTokenAddress(e.target.value)} />
                {tokenAddress && <a href={`https://mevx.io/solana/${tokenAddress}`} target='_blank' rel="noreferrer">
                  <Button icon={<LineChartOutlined />} type="primary">Chart</Button>
                </a>}
              </Space.Compact>
            </Col>
          </Row>
        </Col>
      </Row >
      <Row className='mt-3'>
        <Col span={14}>
          <Row className='overflow-auto wallet-table' style={{ height: '363px' }}>
            <Col span={24}>
              <Row className='wallet-header'>
                <Col span={1} offset={1}>
                  <Checkbox checked={selectedAll} onChange={e => onSelectedAllChecked(e)} />
                  {/* <Form.Check type="checkbox" checked={selectedAll} onChange={e => onSelectedAllChecked(e)} /> */}
                </Col>
                <Col span={9} offset={1} className='text-center'><b>Address</b></Col>
                <Col span={4}><b>Balance</b></Col>
                <Col span={8}><b>Token</b></Col>
              </Row>
            </Col>
            {
              data && data.data.map((item, index) => {
                return (
                  <Col span={24} key={index}>
                    <Row style={{ height: "40px" }} className='wallet-list'>
                      <Col span={1} offset={1}>
                        {/* <Row> */}
                        <Checkbox checked={item.isSelected} onChange={e => onItemChecked(item)}>{index + 1}</Checkbox>
                        {/* <Form.Check className='t' label={index + 1} type="checkbox" checked={item.isSelected} onChange={e => onItemChecked(item)} /> */}
                        {/* {index + 1} */}
                        {/* </Row> */}
                      </Col>
                      <Col span={9} offset={1} className='text-center'>
                        <a style={{ color: 'black' }} href={'https://solscan.io/account/' + item.publicKey} target='_blank' rel="noreferrer">{shortenAddress(item.publicKey)} </a>
                      </Col>
                      <Col span={4}>{item.solBalance} SOL</Col>
                      <Col span={8}>{item.tokenAmount.toLocaleString()} ({item.worth} SOL)</Col>
                    </Row>
                  </Col>
                );
              })
            }
          </Row>
          <Row>
            <Col span={24}>
              <Row style={{ width: privateKey.length > 10 ? '99%' : '100%' }}>
                <Col span={1} offset={1}></Col>
                <Col span={9} offset={1} className='text-center'><b>Sum</b></Col>
                <Col span={4}><b>{data.sum.solBalance} SOL</b></Col>
                <Col span={8}><b>{data.sum.worth} SOL</b></Col>
              </Row>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col md="2" className='mt-2'>
              <Button onClick={onRefresh} className='w-100 h-100' type="primary" disabled={isRefreshLoading} icon={isRefreshLoading ? <LoadingOutlined /> : <RedoOutlined />}>
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={9} offset={1}>
          <Row justify="center">
            <h3>Action</h3>
          </Row>

          <Row className='mt-2'>
            <Col span={12} >
              <b>Jito tip (SOL)</b>
            </Col>
            <Col span={11} offset={1}>
              <b>Slippage (%)</b>
            </Col>
            <Col span={12} className='mt-1'>
              <InputNumber
                style={{ width: '90%' }}
                min="0.00001"
                max="1"
                step="0.005"
                placeholder="Jito tip" value={tip} onChange={(e) => {
                  setTip(+e);
                }}
              />
            </Col>
            <Col span={11} offset={1} className='mt-1'>
              <InputNumber
                style={{ width: '100%' }}
                min="5"
                max="100"
                step="10"
                placeholder="Slippage" value={slippage} onChange={(e) => {
                  setSlippage(+e);
                }}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col span={12}>
              <div className='action-container-buy' style={{ height: '160px', width: "90%" }}>
                <Row justify="center" className='mt-2'>
                  <label className='text-success'><h5>Buy</h5></label>
                </Row>
                <Row style={{ width: '90%', margin: '0 auto' }}>
                <label><b>Amount <span className='text-danger'>(min 0.05 SOL)</span></b></label>
                  <InputNumber
                    style={{ width: '100%' }}
                    step="1"
                    placeholder="Amount" value={amount} onChange={(e) => {
                      setAmount(+e);
                    }}
                  />
                </Row>
                <Row justify="center">
                  <Button type='primary' className='success-button w-50 mt-2 mb-2' disabled={!tokenAddress || !amount || isBuyLoading} icon={isBuyLoading ? <LoadingOutlined /> : <></>} onClick={onBuyClick}>
                    {isBuyLoading ?
                      "Buying" : "Buy"}
                  </Button>
                </Row>
              </div>
            </Col>
            <Col span={11} offset={1}>
              <div className='action-container-sell' style={{ height: '160px' }}>
                <Row justify='center' className='mt-2'>
                  <h5 className='text-danger'>Sell</h5>
                </Row>
                <Row style={{ width: '90%', margin: '0 auto', flexDirection: 'column' }}>
                  <label><b>Percent ({percent}%)</b></label>
                  <Slider
                    className="range"
                    max={100}
                    min={10}
                    value={percent}
                    tooltip={{
                      formatter,
                    }}
                    onChange={(value) => setPercent(value)}
                  />
                </Row>
                <Row justify='center'>
                  <Button type='primary' danger className='w-50 mt-2 mb-2' disabled={!tokenAddress || isSellLoading} icon={isSellLoading ? <LoadingOutlined /> : <></>} onClick={onSellClick}
                  > {isSellLoading ?
                    'Selling' : 'Sell'}</Button>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div >
  );
};

export default PumpfunComponent;