import { useState, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { Modal } from 'antd';
import LogComponent from './components/Log';
import MarketMakerComponent from './components/MarketMaker';
import BulkSenderComponent from './components/BulkSender';
import HeaderComponent from './components/Header';
import { Route, Routes, Navigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import PumpfunComponent from './components/Pumpfun';
import BundlePumpfunComponent from './components/BundlePumpfun';
function App() {;
  const [clientId] = useState(uuidv4());
  const [log, setLog] = useState([]);

  async function newLog(newLogs) {
    setLog([...newLogs, ...log]);
  }

  async function clearLog() {
    setLog([]);
  }

  return isDesktop ? (
    <div className='h-100' style={{ paddingLeft: "50px", paddingRight: "50px" }}>
      <HeaderComponent />
      <Routes>
        <Route path="/ray" element={<MarketMakerComponent clientId={clientId} onNewLog={newLog} />} />
        <Route path='/pumpfun' element={<PumpfunComponent onNewLog={newLog} />}></Route>
        <Route path="/bulk-sender" element={<BulkSenderComponent clientId={clientId} onNewLog={newLog} />} />
        <Route path="/bundle-pumpfun" element={<BundlePumpfunComponent onNewLog={newLog} />} />

        <Route
          path="*"
          element={<Navigate to="/ray" />}
        />
      </Routes>
      <LogComponent log={log} onClearLog={clearLog} />
    </div >
  ) : <>
    <Modal
      visible={true}
      maskClosable={false}
      keyboard={false}
      style={{ color: "red" }}
    >
      <div style={{ fontSize: "16px" }}>
        <p>Market Maker is only access by Desktop/PC</p>
      </div>
    </Modal>
  </>
}

export default App;