import { useState, useEffect } from "react";
import { Row, Col, Space, Input, Button, Radio, InputNumber } from "antd";
import { ReloadOutlined, LoadingOutlined, ForwardOutlined, RedoOutlined } from "@ant-design/icons";
import ReactCodeMirror from '@uiw/react-codemirror';
import { Keypair } from "@solana/web3.js";
import bs58 from 'bs58';
import { useApi, useNotification } from '../hooks';
const BulkSenderComponent = ({ clientId, onNewLog }) => {
    const { post } = useApi();
    const { success, error, warning } = useNotification();

    const [publicKeys, setPublicKeys] = useState();

    const [tokenAddress, setTokenAddress] = useState();
    const [senderPrivateKey, setSenderPrivateKey] = useState();
    const [senderPublicKey, setSenderPublicKey] = useState('');
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [tokenInfo, setTokenInfo] = useState({
        tokenAmount: 0,
        name: '',
    });
    const [tip, setTip] = useState(0.001);
    const [mode, setMode] = useState('fixed');
    const [distributeValue, setDistributeValue] = useState([]);
    const [readyToRun, setReadyToRun] = useState(false);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        if (!senderPrivateKey) return;
        getPublicKey();

    }, [senderPrivateKey])

    useEffect(() => {
        if (!tokenAddress) return;
        getTokenInfo();
    }, [senderPublicKey, tokenAddress])

    async function onPublicKeysChange(value) {
        setPublicKeys(value.split('\n'));
    }

    function getResult() {
        let result = '';
        for (let i = 0; i < distributeValue.length; i++) {
            result += `${distributeValue[i].publicKey},${distributeValue[i].amount}\n`;
        }
        return result;
    }

    async function getTokenInfo() {
        var res = await post('/getTokenInfo',
            {
                publicKey: senderPublicKey,
                mint: tokenAddress
            }
        )
        setTokenInfo(res);
    }

    async function getPublicKey() {
        var res = await post("/getPublicKey", {
            privateKey: senderPrivateKey
        });
        setSenderPublicKey(res.publicKey);
    }

    async function onRun() {
        setIsRunning(true);
        const response = await post('/distro', {
            senderPrivateKey,
            mint: tokenAddress,
            data: distributeValue,
            tip: tip
        });
        onNewLog(response.map(item => {
            return {
                time: new Date().toLocaleString(),
                value: 'TxHash: ' + item.signature,
                status: item.confirmed ? 1 : 0
            }
        }))
        setIsRunning(false);
    }

    function onTest() {
        const totalAmount = tokenInfo.tokenAmount;
        const numberOfPeople = publicKeys.length;
        let baseAmount = totalAmount / numberOfPeople;

        let result = []
        let remainingAmount = totalAmount;

        for (let i = 0; i < numberOfPeople; i++) {
            const percentage = Math.floor(Math.random() * 100 - 40 + 1) + 40;
            let amount = parseInt(baseAmount * percentage / 100);
            remainingAmount -= amount;
            result.push({
                publicKey: publicKeys[i],
                amount: amount
            });
        }
        baseAmount = parseInt(remainingAmount / numberOfPeople);
        for (let i = 0; i < numberOfPeople; i++) {
            const percentage = Math.floor(Math.random() * 70 - 40 + 1) + 40;
            let amount = parseInt(baseAmount * percentage / 100);
            remainingAmount -= amount;
            result[i].amount += amount;
        }
        const remainingAmountPerPerson = parseInt(remainingAmount / numberOfPeople);
        for (let i = 0; i < numberOfPeople; i++) {
            result[i].amount += remainingAmountPerPerson;
            remainingAmount -= remainingAmountPerPerson;
        }
        result[0].amount += parseInt(remainingAmount);
        const sum = result.reduce((acc, item) => acc + item.amount, 0);
        const validToRun = sum <= totalAmount;
        setReadyToRun(validToRun);
        if (!validToRun) {
            onNewLog([{
                time: new Date().toLocaleString(),
                value: `Insufficient balance. Please change the amount then regenerate again`,
                status: 0
            }]);
            return;

        }
        else {
            setDistributeValue(result);
            onNewLog([{
                time: new Date().toLocaleString(),
                value: `${sum.toLocaleString()} ${tokenInfo.name} will be distributed to ${result.length} wallets`,
                status: 1
            }]);
        }
    }
    function generateRandomValue() {
        // Generate random value from min to max
        if (mode === 'fixed') {
            return min;
        } else {
            return Math.floor(Math.random() * (max - min + 1) + min);
        }
    }
    function validateRunButton() {
        if (senderPrivateKey === '' || tokenAddress === '' || tokenInfo.tokenAmount === 0 || publicKeys?.length === 0 || tip === 0) return true;
        return false;
        // if (mode === 'fixed')
        //     return !min;
        // return !min || !max;
    }

    function renderDistributionValue() {
        if (mode === 'fixed') {
            return (
                <Row className="mt-2">
                    <Col span={3}>
                        <b>Amount:</b>
                    </Col>
                    <Col span={10}>
                        <InputNumber value={min} onChange={e => {
                            setMin(e);
                            setMax(e);
                            setDistributeValue([]);
                            setReadyToRun(false);
                        }} style={{ width: "100%" }} placeholder="Amount" />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="mt-2">
                    <Col span={3}>
                        <b>Range:</b>
                    </Col>
                    <Col span={10}>
                        <Row justify="space-between">
                            <Col span={10}>
                                <InputNumber style={{ width: "100%" }} value={min} onChange={e => {
                                    setMin(e);
                                    setDistributeValue([]);
                                    setReadyToRun(false);
                                }} placeholder="Min amount" />
                            </Col>
                            <Col span={10} offset={1}>
                                <InputNumber value={max} onChange={e => {
                                    setMax(e);
                                    setDistributeValue([]);
                                    setReadyToRun(false);
                                }} style={{ width: "100%" }} placeholder="Max amount" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        }
    }

    const generateFile = () => {
        const keypairs = [];
        const numberOfWallets = 10;
        for (let i = 0; i < numberOfWallets; i++) {
            const keypair = Keypair.generate();
            keypairs.push(keypair);
        }
        const publicKeys = keypairs.map(keypair => keypair.publicKey.toBase58());
        setPublicKeys(publicKeys);
        let fileContent = 'publicKey,secretKey\n';
        keypairs.forEach(keypair => {
            fileContent += `${keypair.publicKey.toBase58()},${bs58.encode(keypair.secretKey)}\n`;
        });
        // Create a Blob from the content
        const blob = new Blob([fileContent], { type: 'text/javascript' });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.download = 'wallets.csv';

        // Create a URL for the Blob and set it as the href attribute
        link.href = URL.createObjectURL(blob);

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    return (
        <div className='mt-2'>
            <Row>
                <Col span={11}>
                    <Row>
                        <Col span={3}>
                            <b>Sender key: </b>
                        </Col>
                        <Col span={20}>
                            <Space.Compact
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Input value={senderPrivateKey} placeholder="Please enter sender private key" onChange={e => {
                                    setSenderPrivateKey(e.target.value);
                                    setDistributeValue([]);
                                    setReadyToRun(false);
                                }} />
                            </Space.Compact>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col span={3}>
                            <b>Token mint: </b>
                        </Col>
                        <Col span={20}>
                            <Space.Compact
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Input value={tokenAddress} placeholder="Please enter token address" onChange={e => {
                                    setTokenAddress(e.target.value);
                                    setDistributeValue([]);
                                    setReadyToRun(false);
                                }} />
                                <Button icon={<ReloadOutlined />} type="primary" onClick={getTokenInfo}>Refresh Balance</Button>
                            </Space.Compact>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col span={23} className="action-container-buy">
                            <Row justify="center" className='mt-2'>
                                <h5>Distribution</h5>
                            </Row>
                            <Row>
                                <Col span={22} offset={1}>
                                    <Row>
                                        <Col span={3}>
                                            <b>Sender: </b>
                                        </Col>
                                        <Col span={21}>
                                            <b><label className="text-danger">{senderPublicKey ? senderPublicKey : 'Empty'}</label></b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>
                                            <b>Amount:</b>
                                        </Col>
                                        <Col span={21}>
                                            <b><label className="text-success">{tokenInfo.tokenAmount.toLocaleString()} {tokenInfo.name}</label></b>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className="mt-2" span={22} offset={1}>
                                    {/* <Row>
                                        <Col span={3}>
                                            <b>Mode: </b>
                                        </Col>
                                        <Col span={21}>
                                            <Radio.Group value={mode} onChange={e => {
                                                setMode(e.target.value);
                                                setMin(0);
                                                setMax(0);
                                            }}>
                                                <Radio.Button value="fixed">Fixed Amount</Radio.Button>
                                                <Radio.Button value="range">Range</Radio.Button>
                                            </Radio.Group>
                                        </Col>
                                    </Row> */}
                                    {/*renderDistributionValue()*/}
                                    <Row className="mt-2">
                                        <Col span={3}>
                                            <b>Jito tip (SOL):</b>
                                        </Col>
                                        <Col span={10}>
                                            <Row justify="space-between">
                                                <Col span={10}>
                                                    <InputNumber min="0.001" step="0.001" style={{ width: "100%" }} value={tip} onChange={e => {
                                                        setTip(e);
                                                    }} placeholder="Tip" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-2" justify="center" >
                                <Button disabled={validateRunButton() || isRunning} icon={<RedoOutlined />} onClick={onTest} type="primary">Test </Button>
                                <Button style={{ marginLeft: "1rem" }} disabled={!readyToRun || isRunning} icon={isRunning ? <LoadingOutlined /> : <ForwardOutlined />} onClick={onRun} type="primary">Run</Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <b>Recipients <span className="text-danger">(maximum 36 wallets)</span></b> <Button onClick={generateFile} type="primary">Create 10 wallets</Button>
                    <ReactCodeMirror
                        minHeight='300px'
                        maxHeight='300px'
                        onChange={onPublicKeysChange}
                        value={publicKeys?.join('\n')}
                    />
                </Col>
                <Col span={7}>
                    <b>Result</b>
                    <ReactCodeMirror
                        editable={false}
                        minHeight='300px'
                        maxHeight='300px'
                        onChange={onPublicKeysChange}
                        value={getResult()}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default BulkSenderComponent;